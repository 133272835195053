import monitorReducerEnhancer from "enhancers/monitorReducer";
import loggerMiddleware from "middleware/logger";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";

import { IAuthenState } from "shared/modules/Authen";
import { IP1State } from "shared/modules/P1";

import authen from "store/Authen/authen.reducer";
import P1 from "store/P1/P1.reducer";

export interface IReducer {
  P1: IP1State;
  authen: IAuthenState;
}

const rootReducer = combineReducers({
  P1,
  authen,
});

const middlewareEnhancer = applyMiddleware(loggerMiddleware, thunkMiddleware);
const composedEnhancers: any = compose(
  middlewareEnhancer,
  monitorReducerEnhancer
);

const store = createStore(rootReducer, undefined, composedEnhancers);

export default store;
