import { TError } from "shared/modules/type";

export enum EP1Action {
  GET_P1_REQUEST = "GET_P1_REQUEST",
  GET_P1_SUCCESS = "GET_P1_SUCCESS",
  GET_P1_FAILED = "GET_P1_FAILED",
}

interface IGetP1Request {
  type: EP1Action.GET_P1_REQUEST;
}

interface IGetP1Success {
  type: EP1Action.GET_P1_SUCCESS;
}

interface IGetP1Failed {
  type: EP1Action.GET_P1_FAILED;
  error: TError;
}

export type TP1Types = IGetP1Request | IGetP1Success | IGetP1Failed;
