import { IP1State, initialP1State } from "shared/modules/P1";
import { EP1Action, TP1Types } from "./P1.type";

export default (
  state: IP1State = initialP1State,
  action: TP1Types
): IP1State => {
  switch (action.type) {
    // NOTE: user request
    case EP1Action.GET_P1_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EP1Action.GET_P1_SUCCESS:
      return {
        ...state,
        isPageLoading: false,
      };
    case EP1Action.GET_P1_FAILED:
      return {
        ...state,
        isPageLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
