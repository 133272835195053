import moment from "moment";
import { IDefaultAPI, IDefaultState } from "./interface";

export const initialState: IDefaultState = {
  isLoading: false,
  isPageLoading: false,
  isTableLoading: false,
};

// ;; dto
export const initialApi: IDefaultAPI = {
  language: "th",
  userAgent: "Chrome",
  referenceCode: moment().format("yyyyMMDDHHmmss").toString(),
  actionBy: "",
};
