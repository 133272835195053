import moment from "moment";
import { initialState } from "shared/modules/constant";
import { IP1Form, IP1State } from "./P1.interface";

export const initialP1State: IP1State = {
  ...initialState,
};

export const initialP1Form: IP1Form = {
  AAA: "",
  BBB: "",
  CCC: "",
  DDD: "",
  EEE: "",
  FFF: moment(),
  GGG: "",
  HHH: "",
  III: moment(),
  JJJ: moment(),
  KKK: "",
  LLL: "",
  MMM: moment(),
  NNN: "",
  OOO: "",
  PPP: "",
  QQQ: "",
};
