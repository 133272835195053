import { Theme, ThemeOptions, createTheme } from "@mui/material";
import { blue, lime, orange, purple, red } from "@mui/material/colors";
import { DefaultTheme } from "styled-components";

const handlePalette = {
  c1: "#7030A0",
  c2: "#855fac",
  c3: "#9933FF",
  c4: "#d2a4ff",
  c5: "#510089",
  background: "#ebebeb",
  white: "#fff",
  black: "#000",
  link: "#0000ff",
  disabled: "#9f9f9f",
};

const handleFont = {
  f1: "18px",
  f2: "14px",
  f3: "12px",
};

export const theme: DefaultTheme = createTheme({
  typography: {
    fontFamily: "Kanit",
  },
  /**
   * Pass value from 'theme' to 'file'
   */
  status: {
    danger: orange[500],
  },
  palette: {
    primary: {
      light: handlePalette.c4,
      main: handlePalette.c2,
      dark: handlePalette.c1,
      contrastText: handlePalette.white,
    },
    // secondary: {
    //   light: handlePalette.c4,
    //   main: handlePalette.c3,
    //   dark: handlePalette.c2,
    //   contrastText: handlePalette.white,
    // },
    info: {
      main: "#f00",
    },
  },
  color: {
    c1: handlePalette.c1,
    c2: handlePalette.c2,
    c3: handlePalette.c3,
    c4: handlePalette.c4,
    c5: handlePalette.c5,
    background: handlePalette.background,
    white: handlePalette.white,
    black: handlePalette.black,
  },
  font: {
    f1: handleFont.f1,
    f2: handleFont.f2,
    f3: handleFont.f3,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: handleFont.f2,
          color: handlePalette.white,
        },
      },
    },
    MuiTextField: {
      /**
       * Default value of parameter
       */
      // defaultProps: {
      //   placeholder: "โปรดระบุ",
      // },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // backgroundColor: '#510089',
          fontWeight: 600,
        },
      },
      variants: [
        {
          props: { variant: "text" },
          style: {
            backgroundColor: handlePalette.c5,
            color: handlePalette.white,
            fontWeight: "normal",
            // boxShadow:
            //   "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
            "&.MuiButtonBase-root:hover": {
              backgroundColor: handlePalette.c1,
            },
            "&.MuiButtonBase-root.Mui-disabled": {
              backgroundColor: handlePalette.disabled,
              color: handlePalette.white,
              // backgroundColor: "#ececec",
              // color: "#acacac",
            },
          },
        },
        {
          props: { variant: "text", color: "secondary" },
          style: {
            backgroundColor: handlePalette.c4,
            color: handlePalette.white,
            boxShadow:
              "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
            "&.MuiButtonBase-root:hover": {
              backgroundColor: handlePalette.c1,
            },
          },
        },
        {
          props: { variant: "underline" },
          style: {
            backgroundColor: "transparent",
            textDecoration: "underline",
            color: handlePalette.c1,
            "&.MuiButtonBase-root:hover": {
              backgroundColor: "transparent",
            },
          },
        },
      ],
    },
    MuiButtonBase: {
      /**
       * Override style
       */
      styleOverrides: {
        root: {
          // backgroundColor: handlePalette.c2,
          // "&.MuiButtonBase-root": {
          //   color: handlePalette.white,
          // },
          // "&.MuiButtonBase-root:hover": {
          //   backgroundColor: handlePalette.c3,
          // },
          //   height: 50,
          // alignSelf: "center",
          /**
           * Css for calendar
           */
          // "&.MuiPickersDay-root": {
          //   color: handlePalette.black,
          // },
          // "&.MuiPickersDay-root.Mui-selected": {
          //   backgroundColor: handlePalette.c1,
          // },
          // "&.MuiPickersDay-root.Mui-selected:focus": {
          //   backgroundColor: handlePalette.c1,
          // },
        },
      },
    },
    // MuiMenuItem: {
    //   styleOverrides: {
    //     root: {
    //       color: handlePalette.white,
    //       "&.MuiButtonBase-root": {
    //         color: handlePalette.black,
    //       },
    //       "&.Mui-selected": {
    //         backgroundColor: handlePalette.c1,
    //       },
    //     },
    //   },
    // },
    MuiListItem: {
      styleOverrides: {
        root: {
          "&.MuiListItem-root": {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: handlePalette.c1,
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          ".MuiTableCell-alignCenter": {
            align: "center"
          },
          "> .MuiTableRow-head": {
            // backgroundColor: handlePalette.c2,
            backgroundColor: "#d8dcdf",
          },
          th: {
            color: handlePalette.black,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "&.MuiPaper-root": {
            boxShadow: "unset",
          },
        },
      },
    },
  },
});
