import { IAuthenState, initialAuthenState } from "shared/modules/Authen";
import { EAuthenAction, TAuthenTypes } from "./authen.type";

export default (
  state: IAuthenState = initialAuthenState,
  action: TAuthenTypes
): IAuthenState => {
  switch (action.type) {
    // NOTE: user request
    case EAuthenAction.AUTHEN:
      return {
        ...state,
        ...action.data,
        isAuthen: true,
      };
    case EAuthenAction.NOT_AUTHEN:
      return {
        ...state,
        isAuthen: false,
      };
    case EAuthenAction.AUTHEN_LOGIN:
      return {
        ...state,
        ...action.data,
        isAuthen: true,
        // menuList: action.data.menuList,
        // actionBy: action.data.actionBy,
        // displayName: action.data.displayName,
        // agencyName: action.data.agencyName,
        // menuDefault: action.data.menuDefault,
        // token: action.data.token,
        // audience: action.data.audience,
        // issuer: action.data.issuer,
        // issueDateTime: action.data.issueDateTime,
        // expireIn: action.data.expireIn,
        // expireDateTime: action.data.expireDateTime,
        // positionName: action.data.positionName,
        // currentFyNo: action.data.currentFyNo,
      };
    case EAuthenAction.AUTHEN_LOGOUT:
      return {
        ...state,
        isAuthen: false,
        actionBy: "",
        displayName: "",
        agencyName: "",
        menuDefault: "",
        menuList: [],
      };
    default:
      return {
        ...state,
      };
  }
};
