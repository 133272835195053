import { Box } from "@mui/material";
import { styled } from "styled-components";
import { theme } from "themes/T1";
import LogoutIcon from '@mui/icons-material/Logout';

export const Section = styled(Box)`
  background: rgb(81, 0, 137);
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  display: flex;
  height: 70px;
  width: 100%;
  gap: 20px;
  padding: 0 20px;
  justify-content: space-between;
`;

export const Panel = styled(Box)`
  display: flex;
  align-items: center;
`;

export const PanelText = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Logo = styled.img`
  /* width: 112px; */
  height: 54px;
  aspect-ratio: 1 / 1;
`;

export const TitleText = styled.span`
  font-size: ${theme.font.f1};
  color: ${theme.color.white};
`;

export const OfficerSection = styled.div`
  display: flex;
  gap: 1%;
  width: 100%;
`;

export const UserProfileWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const UserNameText = styled.div`
  color: ${theme.color.white};
  font-size: ${theme.font.f2};
  font-weight: bold;
  cursor: pointer;
  margin-right: 14px;
  width: max-content;
`;

export const LogoutIconWrapper = styled.div`
  border-left: 1px solid rgb(109, 109, 119);
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
`;

export const LogoutButton = styled(LogoutIcon)`
 
  font-size: 2rem !important;
  margin: 12px;
  color: #ffffff;
  margin-right: 2rem;
  @media (max-width: 600px) {
    font-size: 1.7rem;
    margin-top: 0.4em;
    margin-right: 0em;
  }
`;
