import { ThemeProvider } from "@mui/material";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "store";
import { theme } from "themes/T1";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import UnAuthen from "./router/UnAuthen.router";

import "react-toastify/dist/ReactToastify.css";

import dayjs from "dayjs";
import "dayjs/locale/th";
import buddhistEra from "dayjs/plugin/buddhistEra";

dayjs.extend(buddhistEra);
dayjs.locale("th");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
//
const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <UnAuthen />
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
