import styled from "@emotion/styled";
import { FC } from "react";

const FooterWrapper = styled.div`
  width: 100vw;
  height: 40px;
  background-color: rgb(81, 0, 137);
  position: fixed;
  bottom: 0;
  z-index: 101;
`;

const Footer: FC = () => {
  return <FooterWrapper />;
};

export default Footer;
