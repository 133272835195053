import logo from "assets/logo.png";
import React from "react";
import { Logo, LogoutButton, LogoutIconWrapper, OfficerSection, Panel, PanelText, Section, TitleText, UserNameText, UserProfileWrapper } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { authenLogout } from "store/Authen/authen.action";
import { useNavigate } from "react-router-dom";
import { IReducer } from "store";

interface ITitleRegisterProps { }

const TitleRegister: React.FC<ITitleRegisterProps> = (props) => {
  const { } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((store: IReducer) => store.authen);

  const onLogout = () => {
    dispatch(authenLogout());
  };

  const onManage = () => {
    navigate(`/userManage/${user.actionBy}`, { state: "title" })
  };

  return (
    <Section id="title">
      <OfficerSection>
        <Panel>
          <Logo src={logo} />
        </Panel>
        <PanelText>
          <TitleText>{"องค์การบริหารส่วนจังหวัดนครสวรรค์"}</TitleText>
        </PanelText>
      </OfficerSection>

      {/* <UserProfileWrapper>
        <UserNameText onClick={onManage}>{user.displayName}</UserNameText>
        <LogoutIconWrapper color={"#fff"} onClick={onLogout}>
          <LogoutButton ></LogoutButton>
        </LogoutIconWrapper>
      </UserProfileWrapper> */}
    </Section>
  );
};

export default TitleRegister;
