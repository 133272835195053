export type TError = Error | unknown;

export type TSelect = number | string | undefined;

export type TInput = string | undefined;

// export type TDate = moment.Moment | undefined;
export type TDate = Date | undefined;

export type TFile = File | null;

export enum EFileAcceptable {
  AllImages = "image/*",
  // AllImages = "image",
  PDF = "application/pdf",
  XML = "application/xml",
  CSV = "application/csv",
  DOCX = "application/docx",
  XMLS = "application/vnd.ms-excel",
  XMLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  XLS = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
}

export enum ELocalStorage {
  TOKEN = "TOKEN",
  MENU = "MENU",
  ACTION_BY = "ACTION_BY",
  VERSION = "VERSION",
  USER = "USER",
  PERMISSION = "PERMISSION"
}
