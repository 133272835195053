import { IDTOAuthenLoginResponse } from "controllers/Authen/interface";

export enum EAuthenAction {
  AUTHEN = "AUTHEN",
  NOT_AUTHEN = "NOT_AUTHEN",
  AUTHEN_LOGIN = "AUTHNE_LOGIN",
  AUTHEN_LOGOUT = "AUTHEN_LOGOUT",
}

interface IAuthen {
  type: EAuthenAction.AUTHEN;
  data: any;
}

interface INotAuthen {
  type: EAuthenAction.NOT_AUTHEN;
}

// login
interface IAuthenLogin {
  type: EAuthenAction.AUTHEN_LOGIN;
  data: IDTOAuthenLoginResponse;
}

// logout
interface IAuthenLogout {
  type: EAuthenAction.AUTHEN_LOGOUT;
}

export type TAuthenTypes = IAuthen | INotAuthen | IAuthenLogin | IAuthenLogout;
