import Footer from "components/Footer";
import Nav from "components/Nav";
import PageLoading from "components/PageLoading";
import Title from "components/Title";
import TitleRegister from "components/TitleRegister";
import React, { Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import version from "shared/config/version";
import { ELocalStorage } from "shared/modules/type";
import { IReducer } from "store";
import { authen, verifyToken } from "store/Authen/authen.action";

const AuthenPage = React.lazy(() => import("pages/Authen"));
const ForgetPasswordPage = React.lazy(() => import("pages/ForgetPassword"));
const ChangePasswordPage = React.lazy(() => import("pages/ChangePassword"));
const ResetPasswordPage = React.lazy(() => import("pages/ResetPassword"));

const AuthenRouter = React.lazy(() => import("router/Authen.router"));
const RegisterPage = React.lazy(() => import("pages/Register"));

interface IUnAuthenProps { }

const UnAuthen: React.FC<IUnAuthenProps> = (props) => {
  const { } = props;
  const dispatch = useDispatch();

  const isAuthen = useSelector((store: IReducer) => store.authen.isAuthen);
  // const isAuthen = true;


  const Loading = () => {
    return (
      <figure>
        <div className="dot white"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </figure>
    );
  };

  React.useEffect(() => {
    if (version.version !== localStorage.getItem(ELocalStorage.VERSION))
      localStorage.clear();
    dispatch(verifyToken());
  }, [dispatch]);

  return (
    <>
      <React.Suspense fallback={<Loading />}>
        {/* <Title /> */}
        {/* <Suspense fallback={<Loading />}> */}
        <Routes>
          <Route
            path="/authen"
            element={isAuthen ? <Navigate to="/" replace /> : <AuthenPage />}
          />
          <Route
            path="/forget-password"
            element={
              isAuthen ? <Navigate to="/" replace /> : <ForgetPasswordPage />
            }
          />
           <Route
            path="/Change-Password"
            element={
              isAuthen ? <Navigate to="/" replace /> : <ChangePasswordPage />
            }
          />
          <Route
            path="/set-password"
            element={
              isAuthen ? <Navigate to="/" replace /> : <ResetPasswordPage />
            }
          />
           <Route
          path="/register"
          element={
            <React.Suspense fallback={<Loading />}>
              <>
                <TitleRegister />
                <RegisterPage />
              </>
            </React.Suspense>
          }
        />
          <Route
            path="*"
            element={
              isAuthen ? <AuthenRouter /> : <Navigate to="/authen" replace />
            }
          />
        </Routes>
        {/* </Suspense>  */}
        <Footer />
      </React.Suspense>

    </>
  );
};

export default UnAuthen;
